








































































































































































































































































































.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
